@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600&display=swap');
* {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  border: none;
  text-transform: capitalize;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding-top: 5rem;
  overflow-x: hidden;
}

html::-webkit-scrollbar {
  width: 1rem;
}

html::-webkit-scrollbar-track {
  background: #000;
}

html::-webkit-scrollbar-thumb {
  background: #F2960D;
}

body {
  background: #000;
}

section {
  padding: 5rem 9%;
}

.heading {
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
}

.heading::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 0.1rem;
  width: 100%;
  background: #aaa;
  z-index: -1;
}

.heading span {
  font-size: 3rem;
  color: #F2960D;
  background: #000;
  padding: 0.5rem 1.5rem;
  border: 0.1rem solid #aaa;
  border-radius: 0.5rem;
}

.btn {
  margin-top: 1rem;
  display: inline-block;
  padding: 1rem 2.8rem;
  padding-right: 3rem;
  background: linear-gradient(130deg, #F2960D 93%, transparent 90%);
  color: #fff;
  cursor: pointer;
  font-size: 1.7rem;
}

.btn:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media (max-width: 991px) {
  html {
    font-size: 55%;
    scroll-padding: 7rem;
  }
  .header {
    padding: 0 2rem;
  }
  section {
    padding: 3rem 2rem;
  }
  .home .slide {
    padding: 2rem 5%;
  }
  .home .slide .content {
    width: 50rem;
  }
  .home .slide .content h3 {
    font-size: 4rem;
  }
}

@media (max-width: 768px) {
  #menu-btn {
    display: inline-block;
  }
  #menu-btn.fa-times {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .header {
    padding: 2rem;
  }
  .header .navbar {
    position: absolute;
    top: 99%;
    left: 0;
    right: 0;
    background: #000;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  .header .navbar.active {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .header .navbar a {
    display: block;
    font-size: 2rem;
  }
  .about {
    gap: 3rem;
  }
  .about .image {
    margin-top: 5rem;
  }
  .about .content .title {
    font-size: 3rem;
  }
  .benifits .box-container .box.second {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
}

@media (max-width: 450px) {
  html {
    font-size: 50%;
  }
}
