/** header style **/

@font-face {
  font-family: 'Payback';
  src: url('/public/PaybAck.ttf');
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 9%;
  z-index: 10000;
  background: #000;
}

.header .logo {
  font-weight: bolder;
  color: #fff;
  font-size: 3rem;
}

.header img {
  height: 60px;
}

.header .logo span {
  color: #f2960d;
}

.header .navbar a {
  display: inline-block;
  padding: 1.5rem 2rem;
  font-size: 1.7rem;
  color: #fff;
}

.header .navbar span {
  display: inline-block;
  padding: 1.5rem 2rem;
  font-size: 1.7rem;
  color: #fff;
}

.header .navbar .notClicked {
  display: none;
  padding: 1.5rem 2rem;
  font-size: 1.7rem;
  color: #fff;
}

.header .navbar a:hover {
  background: #f2960d;
}

#menu-btn {
  font-size: 3rem;
  color: #fff;
  cursor: pointer;
  display: none;
}

@media (max-width: 768px) {
  #menu-btn {
    display: inline-block;
  }
  #menu-btn.fa-times {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .header {
    padding: 1rem 2rem;
  }
  .header img {
    height: 50px;
  }
  .header .navbar {
    position: absolute;
    top: 99%;
    left: 0;
    right: 0;
    background: #000;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  .header .navbar.active {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .header .navbar a {
    display: block;
    font-size: 2rem;
  }
  .header .navbar span {
    display: block;
    font-size: 2rem;
  }

  .header .navbar .showProductType {
    display: flex;
    font-size: 2rem;
    justify-content: space-between;
  }
}

/** Footer Style **/

.footer .box-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(25rem, 1fr)) [auto-fit];
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 1.5rem;
}

.footer .box-container .box h3 {
  font-size: 2.2rem;
  color: #fff;
  padding: 1rem 0;
}

.footer .box-container .box .links {
  font-size: 1.5rem;
  display: block;
  color: #aaa;
  padding: 1rem 0;
}

.footer .box-container .box .links:hover {
  color: #f2960d;
}

.footer .box-container .box p {
  font-size: 1.5rem;
  color: #aaa;
  padding: 1rem 0;
}

.footer .box-container .box p i {
  padding-right: 0.5rem;
  color: #f2960d;
}

.footer .box-container .box .share {
  padding: 1rem 0;
}

.footer .box-container .box .share a {
  height: 4.5rem;
  width: 4.5rem;
  line-height: 4.5rem;
  font-size: 1.7rem;
  color: #fff;
  background: #111;
  border-radius: 50%;
  margin-right: 0.5rem;
  text-align: center;
}

.footer .box-container .box .share a:hover {
  background: #f2960d;
}

.footer .box-container .box form .email {
  margin-bottom: 1rem;
  width: 100%;
  background: #111;
  padding: 1.2rem;
  font-size: 1.5rem;
  color: #fff;
  text-transform: none;
}

.credit {
  font-size: 2rem;
  text-align: center;
  padding: 2rem;
  color: #fff;
  background: #111;
  line-height: 1.5;
}

.credit span {
  color: #f2960d;
}

/** Home Stying **/

.home {
  padding: 0;
  margin-top: 5rem;
}

.home .slide {
  min-height: 70vh;
  background-size: cover !important;
  background-position: center !important;
  padding: 2rem 9%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.home .slide .content {
  width: 60rem;
}

.home .slide img {
  height: 100%;
  width: 100%;
  animation: fadeIn 2s ease-in-out forwards;
}

.home .slide .content span {
  font-size: 2rem;
  color: #f2960d;
  display: block;
}

.home .slide .content h3 {
  font-size: 5rem;
  color: #fff;
  padding: 0.5rem 1.5rem;
  animation: fadeIn 2s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.swiper-pagination-bullet {
  height: 2rem;
  width: 2rem;
  background: #fff;
  border-radius: 0;
}

.home .slide1 {
  background-image: url(../../public/images/home-bg-1.jpg);
  background-repeat: no-repeat;
}
.home .slide2 {
  background-image: url(../../public/images/home-bg-2.jpg);
  background-repeat: no-repeat;
}
.home .slide3 {
  background-image: url(../../public/images/home-bg-1.jpg);
  background-repeat: no-repeat;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #f2960d;
}

/** Aboutus Styling **/

.about {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 5rem;
}

.about .image {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 42rem;
  flex: 1 1 42rem;
  position: relative;
}

.about .image::before,
.about .image::after {
  content: '';
  position: absolute;
  z-index: -1;
  background: #f2960d;
  height: 15rem;
  width: 15rem;
}

.about .image::before {
  top: 0;
  left: 0;
}

.about .image::after {
  bottom: 0;
  right: 0;
}

.about .image img {
  width: 100%;
  padding: 0.5rem;
}

.about .image2 img {
  width: 100%;
  padding: 2rem;
}

.about .content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 42rem;
  flex: 1 1 42rem;
}

.about .content span {
  font-size: 3rem;
  color: #f2960d;
}

.about .content .title {
  font-size: 3rem;
  color: #fff;
  margin-top: 0.5rem;
  text-align: center;
}

.about .content .title .c-name {
  font-size: 3rem;
  color: #f2960d;
  margin-top: 0.5rem;
  font-weight: 800;
}

.about .content p {
  font-size: 2rem;
  color: #fff;
  padding: 1rem 0;
  line-height: 2;
  text-align: center;
  margin-top: 4rem;
}

/** Benifits Styles**/

.benifits {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.benifits .box-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(32rem, 1fr)) [auto-fit];
  grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
  gap: 1.5rem;
  gap: 0;
  margin-top: 5rem;
}

.benifits .box-container .box {
  background: #111;
}

.benifits .box-container .second {
  margin-top: 5rem;
}

.benifits .box-container .box:hover .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.benifits .box-container .box .image {
  height: 40rem;
  width: 100%;
  overflow: hidden;
}

.benifits .box-container .box .image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.benifits .box-container .box .content {
  padding: 2rem;
  text-align: center;
}

.benifits .box-container .box .content-annimation {
  animation: fadeIn 2s ease-in-out forwards;
}

.benifits .box-container .box .content img {
  height: 9rem;
  margin-bottom: 1rem;
  -webkit-filter: invert(1);
  filter: invert(1);
}

.benifits .box-container .box .content h3 {
  font-size: 2rem;
  color: #f2960d;
}

.benifits .box-container .box .content p {
  line-height: 2;
  font-size: 1.5rem;
  color: #aaa;
  padding: 1rem 0;
}

/** Ajuthenticity Styles **/

.aunthenticity {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(32rem, 1fr)) [auto-fit];
  grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
  gap: 1.5rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.aunthenticity .information span {
  font-size: 2rem;
  color: #f2960d;
}

.aunthenticity .information h3 {
  font-size: 4rem;
  padding-top: 0.5rem;
  color: #fff;
  text-align: center;
}

.aunthenticity .information img {
  width: 100%;
}

.aunthenticity .information p {
  line-height: 2;
  padding: 1rem 0;
  font-size: 1.4rem;
  color: #aaa;
}

.aunthenticity .information p i {
  padding-right: 1rem;
  color: #f2960d;
}

.aunthenticity .plan {
  text-align: center;
  padding: 2rem;
}

.aunthenticity .plan.basic {
  background: linear-gradient(130deg, #111 93%, transparent 90%);
}

.aunthenticity .plan h3 {
  font-size: 3.5rem;
  margin: 1rem 0;
  color: #f2960d;
}

.aunthenticity .plan .info p {
  font-size: 1.75rem;
  margin: 1rem 0;
  color: #fff;
  margin-bottom: 2rem;
}

.aunthenticity .plan .price {
  font-size: 5rem;
  font-weight: bolder;
  color: #f2960d;
}

.aunthenticity .plan .price span {
  color: #fff;
  font-size: 4rem;
}

.aunthenticity .plan .form {
  padding: 1rem 0;
  justify-content: center;
}

.aunthenticity .plan .form .code {
  height: 4rem;
  width: 80%;
  /* border: #aaa 0.5px solid; */
  color: black;
  background-color: #aaa;
  text-align: center;
  font-size: large;
  text-transform: none;
}

.aunthenticity .plan .form .btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/** Product Styling **/

.products .box-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(29rem, 1fr)) [auto-fit];
  grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
  gap: 4rem;
}

.products .box-container .box {
  height: 60rem;
  overflow: hidden;
  position: relative;
}

.products .box-container .box img {
  height: 80%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.products .box-container .box .content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #111;
  padding: 2rem;
}

.products .box-container .box .content span {
  font-size: 1.5rem;
  color: #f2960d;
}

.products .box-container .box .content h2 {
  font-family: 'Payback';
  letter-spacing: 0.2rem;
  font-size: 3rem;
  color: #fff;
  padding-top: 0.5rem;
  display: flex;
  justify-content: center;
}

.products .box-container .box .content h3 {
  font-size: 2rem;
  color: #f2960d;
  padding-top: 0.5rem;
  display: flex;
  justify-content: center;
}

.products .box-container .box .content .share {
  padding-top: 2rem;
  margin-top: 1.5rem;
  border-top: 0.1rem solid #aaa;
}

.products .box-container .box .content .share a {
  font-size: 2rem;
  color: #fff;
  margin-right: 1.5rem;
}

/* Product Detail CSS */

.product-detail .box-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(29rem, 1fr)) [auto-fit];
  grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
  gap: 1.5rem;
}

.product-detail .box-container .box {
  height: auto;
  position: relative;
}

.product-detail .box-container .box img {
  height: 100%;
  width: 100%;
  overflow: hidden;
  -o-object-fit: cover;
  object-fit: cover;
}

.product-detail .box-container .box .content {
  position: relative;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background: #111;
  padding: 2rem;
  overflow: hidden;
}

.product-detail .box-container .box .content span {
  font-size: 1.5rem;
  color: #f2960d;
}

.product-detail .box-container .box .content h1 {
  font-family: 'Payback';
  letter-spacing: 0.2rem;
  font-size: 4rem;
  color: #f2960d;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.product-detail .box-container .box .content h3 {
  font-size: 2rem;
  color: #fff;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.product-detail .box-container .box .content .share {
  padding-top: 2rem;
  margin-top: 1.5rem;
  border-top: 0.1rem solid #aaa;
}

.product-detail .box-container .box .content p {
  font-size: 2rem;
  color: #fff;
  margin-right: 1.5rem;
  padding-top: 1.5rem;
}

.product-detail .box-container .box .content .detail {
  margin-top: 4rem;
}

.product-detail .box-container .box .content .detail .d p {
  font-size: 2rem;
  color: #fff;
  margin-right: 1.5rem;
  padding-top: 0%;
}

.product-detail .box-container .box .content .detail .d {
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.product-detail .box-container .box .content .detail .d h3 {
  font-size: 2rem;
  color: #f2960d;
  display: block;
}

.product-detail .box-container .box .content .detail .dc h3 {
  color: #f2960d;
}

.product-detail .box-container .box .content .detail .dc p {
  text-align: center;
}

/* Toast css */

.Toastify__toast-body > div:last-child {
  font-size: large;
}

/* Real Fake header */

.realFakeHeader {
  background-color: #f2960d;
}

.realFakeHeader span {
  display: flex;
  color: #fff;
}

.realFakeHeader span h3 {
  font-size: 2rem;
  font-weight: 900;
}

.realFakeHeader .link {
  padding-left: 4rem;
  font-size: 2rem;
}

/* Real Fake css */

.realFake .container {
  margin-bottom: 2rem;
}

.realFake .extra-mg {
  margin-bottom: 6rem;
}

.realFake .container h1 {
  color: #fff;
  font-size: x-large;
  text-align: center;
}

.realFake h2 {
  color: #f2960d;
  font-size: large;
  text-align: center;
  margin-bottom: 5rem;
}

.realFake h2 .white {
  color: #fff;
  font-size: large;
  text-align: center;
  margin-bottom: 5rem;
}

.realFake h2 .red {
  color: red;
  font-size: large;
  text-align: center;
  margin-bottom: 7rem;
  font-family: 'Payback';
}

.realFake .container .row {
  display: flex;
  flex-wrap: wrap;
  padding: 4px;
}

/* Create two equal columns that sits next to each other */
.realFake .container .column {
  float: left;
  padding: 0 4px;
  width: 50%;
}

.realFake .container .column img {
  margin-top: 8px;
  width: 100%;
  /* height: 70%; */
}

.realFake .container .column h3 {
  font-size: 2rem;
  text-align: center;
  color: #fff;
}

.realFake .container .column p {
  font-size: 1.5rem;
  text-align: center;
  color: #f2960d;
}
